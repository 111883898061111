import React from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import FilterItems from "./FilterItems"
import NewItem from "./NewItem"
import FilteredResults from "./FilteredResults"
import Login from "./Login"
// const NewItem = React.lazy(() => import("./NewItem"))

export default function Pages() {
	let location = useLocation()

	return (
		<Routes location={location} key={location.pathname}>
			<Route path="/" element={<Login />} />
			<Route path="/filteritems" element={<FilterItems />} />
			<Route path="/newitem" element={<NewItem />} />
			<Route path="/filteredresults" element={<FilteredResults />} />
		</Routes>
	)
}
