function getLocation() {
	let winLocation = window.location.href
	winLocation = winLocation.slice(-1) === "/" ? winLocation.substring(0, winLocation.length - 1) : winLocation

	let serverLocation
	// if (winLocation.includes(5500) || winLocation.includes("local")) {
	// serverLocation = "http://localhost:3001"
	// } else {
	// serverLocation = "https://filmdata-subbie-react-server.herokuapp.com"
	serverLocation = "https://filmdata-subbie-react-server.onrender.com"
	// }
	return serverLocation
}

export const serverLocation = getLocation()
