import React, { useEffect } from "react"
import FormNewItem from "../components/FormNewItem"
import Nav from "../components/Nav"
import { useNavigate } from "react-router-dom"

export default function NewItem() {
	const navigate = useNavigate()

	useEffect(() => {
		if (!localStorage.getItem("user")) {
			navigate("/")
		}
	}, [])

	return (
		<div>
			<Nav />
			<FormNewItem />
		</div>
	)
}
