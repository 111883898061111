import React, { useState, useEffect } from "react"
import GridMUI from "../components/GridMUI"
import { useLocation } from "react-router-dom"
import Nav from "../components/Nav"
import { useNavigate } from "react-router-dom"

export default function FilteredResults() {
	const [rows, setRows] = useState([])
	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		if (!localStorage.getItem("user")) {
			navigate("/")
		} else {
			setRows(location.state.v1)
		}
	}, [])

	return (
		<>
			<Nav />
			<GridMUI rows={rows} />
		</>
	)
}
