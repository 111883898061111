import React from "react"
import { FiFilter, FiPlusSquare, FiLogOut } from "react-icons/fi"
import { IconList, StyledLink, StyledLogout } from "./StyledComponents"
import { useNavigate } from "react-router-dom"

export default function Nav() {
	const navigate = useNavigate()

	function clickHandler(e) {
		if (localStorage.getItem("user")) {
			localStorage.removeItem("user")
		}
		navigate("/")
	}

	return (
		<div>
			<IconList>
				<StyledLink to={"/filteritems"}>
					<FiFilter />
					<h4>Filter Movies</h4>
				</StyledLink>
				<StyledLink to={"/newitem"}>
					<FiPlusSquare />
					<h4>New Movie</h4>
				</StyledLink>
				<StyledLogout onClick={clickHandler}>
					<FiLogOut />
					<h4>Logout</h4>
				</StyledLogout>
			</IconList>
		</div>
	)
}
