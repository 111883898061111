import styled from "styled-components"
import { deviceWidth, deviceHeight } from "../device"
import { NavLink } from "react-router-dom"
import { motion } from "framer-motion"

export const IconList = styled.div`
	display: flex;
	justify-content: center;
	margin: 0.5rem 0rem;

	@media ${deviceHeight.mobileM} {
		margin: 0rem;
	}
`

export const StyledLink = styled(NavLink)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 0rem 0.3rem;
	border-radius: 50%;
	text-decoration: none;
	background: linear-gradient(35deg, #494949, #313131);
	width: 6rem;
	height: 6rem;
	cursor: pointer;
	transform: scale(0.8);

	@media ${deviceWidth.mobileM} {
		margin: 0rem 0rem;
		width: 4rem;
		height: 4rem;
	}

	@media ${deviceHeight.mobileM} {
		margin: 0rem 0rem;
		width: 4rem;
		height: 4rem;
	}

	h4 {
		color: white;
		font-size: 0.8rem;

		@media ${deviceWidth.mobileM} {
			font-size: 0.5rem;
		}

		@media ${deviceHeight.mobileM} {
			font-size: 0.5rem;
		}
	}

	svg {
		color: white;
		font-size: 1.5rem;

		@media ${deviceHeight.mobileM} {
			font-size: 1rem;
		}
	}

	&.active {
		background: linear-gradient(to right, #f27121, #e94057);
	}
`

export const StyledLogout = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 0rem 0.3rem;
	border-radius: 50%;
	text-decoration: none;
	background: linear-gradient(35deg, #494949, #313131);
	width: 6rem;
	height: 6rem;
	cursor: pointer;
	transform: scale(0.8);

	@media ${deviceWidth.mobileM} {
		margin: 0rem 0rem;
		width: 4rem;
		height: 4rem;
	}

	@media ${deviceHeight.mobileM} {
		margin: 0rem 0rem;
		width: 4rem;
		height: 4rem;
	}

	h4 {
		color: white;
		font-size: 0.8rem;

		@media ${deviceWidth.mobileM} {
			font-size: 0.5rem;
		}

		@media ${deviceHeight.mobileM} {
			font-size: 0.5rem;
		}
	}

	svg {
		color: white;
		font-size: 1.5rem;

		@media ${deviceHeight.mobileM} {
			font-size: 1rem;
		}
	}

	&.active {
		background: linear-gradient(to right, #f27121, #e94057);
	}
`

export const Grid = styled(motion.div)`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	grid-gap: 3rem;
	margin: 1.5rem 0rem;

	@media ${deviceWidth.mobileM} {
		grid-template-columns: repeat(1, 1fr);
		margin: 0.5rem 0rem;
	}

	@media ${deviceHeight.mobileM} {
		grid-template-columns: repeat(2, 1fr);
		margin: 0.5rem 0rem;
	}
`

export const Card = styled.div`
	img {
		border-radius: 2rem;
		width: 100%;
	}

	a {
		text-decoration: none;
	}

	h4 {
		position: 1.5rem;
		text-align: center;
		padding: 1rem;
	}
`

export const StyledForm = styled.form`
	margin: 0rem 20rem;
	display: block;
	margin-left: auto;
	margin-right: auto;
	min-width: 30vw;
	max-width: 55vw;

	@media ${deviceWidth.mobileM} {
		max-width: 75vw;
	}

	div {
		position: relative;
	}

	input,
	select {
		background: #494949;
		font-size: 1.5rem;
		color: white;
		padding: 1rem 2rem;
		border: none;
		border-radius: 1rem;
		outline: none;
		width: 100%;
		margin: 0.7rem 0rem;

		@media ${deviceWidth.mobileM} {
			font-size: 0.7rem;
			margin: 1rem 0rem;
		}

		@media ${deviceHeight.mobileM} {
			font-size: 0.9rem;
			height: 1.7rem;
			margin: 0.2rem 0rem;
		}
	}

	select {
		appearance: none;
		background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
		background-repeat: no-repeat;
		background-position: right 1rem center;
		background-size: 1.5rem;
	}

	svg {
		position: absolute;
		top: 50%;
		color: white;
		transform: translate(100%, -50%);
	}

	#magnifier {
		left: 0%;
	}

	.clear {
		right: 2.5rem;
		cursor: pointer;
		font-size: 1.5rem;
	}

	h4 {
		margin: 1rem 1rem 1.5rem 0rem;

		@media ${deviceHeight.mobileM} {
			margin-bottom: 0.5rem;
		}
	}

	label {
		margin: 1rem 0rem 0.5rem 0rem;
		font-size: 1.5rem;

		@media ${deviceWidth.mobileM} {
			font-size: 1rem;
		}

		@media ${deviceHeight.mobileM} {
			font-size: 1rem;
		}
	}

	.filtering-checkboxes {
		margin-top: 1rem;
		background-color: #494949;
		color: white;
		border: none;
		border-radius: 1rem;
		padding: 1rem 2rem;

		@media ${deviceWidth.mobileM} {
			margin-top: 1rem;
		}

		@media ${deviceHeight.mobileM} {
			margin-top: 0.3rem;
			margin-bottom: 0.5rem;
			display: flex;
			padding: 0rem 2rem;
		}
	}

	.filtering-elements {
		display: block;
		margin: 1rem 0rem;

		@media ${deviceHeight.mobileM} {
			text-align: center;
			margin: 0.2rem 0rem;
			line-height: 2rem;
		}
	}

	.checkboxes {
		margin: 0.3rem;
		text-align: center;
	}

	.checkbox {
		width: 1.5rem;
		height: 1.5rem;
		margin: 0rem 1rem;
	}

	input[type="checkbox"] {
		-webkit-appearance: checkbox;
		display: inline-block;
	}

	.checkboxlabel {
		text-align: right;
		width: 6rem;
	}

	button {
		background-color: #494949;
		color: white;
		border: 5px solid #999999;
		height: 2.5rem;
		width: 7rem;
		border-radius: 12px; /* sarok lekerekítés mértéke */
		margin: 1.5rem auto;
		padding: 0.4rem;
		text-align: center;
		text-decoration: none;
		font-size: 100%;
		font-weight: bold;
		display: block;
		transition-duration: 0.4s; /* "hover" stílus átváltási sebessége */
		cursor: pointer; /* kurzor típus rámutatáskor */

		@media ${deviceHeight.mobileM} {
			margin-top: 0.3rem;
		}

		:hover {
			background-color: #549aeb;
			color: lightgray;
		}
	}
`

export const DetailWrapper = styled.div`
	margin: 2rem 0rem 5rem 0rem;
	display: flex;

	@media ${deviceWidth.mobileM} {
		margin: 1rem 0rem 5rem 0rem;
		display: grid;
	}

	@media ${deviceHeight.mobileM} {
		flex-direction: column;
		margin: 1rem 0rem 5rem 0rem;
	}

	.active {
		background: linear-gradient(35deg, #494949, #313131);
		color: white;
	}

	h4 {
		margin: 1rem 0rem;
		font-size: 1rem;
	}

	li {
		font-size: 1rem;
	}

	ul {
		margin: 2rem 0rem;
	}
`

export const DetailTop = styled.div`
	margin-bottom: 2rem;

	@media ${deviceWidth.mobileM} {
		margin: 1rem 1.5rem;
	}

	@media ${deviceHeight.mobileM} {
		margin: 0.5rem 2rem;
	}

	img {
		@media ${deviceWidth.mobileM} {
			margin: 0rem 0.5rem;
			width: 80vw;
		}

		@media ${deviceHeight.mobileM} {
			margin: 1rem 2rem;
			width: 40vw;
		}
	}

	h2 {
		margin-bottom: 2rem;

		@media ${deviceWidth.mobileM} {
			margin: 1rem 1.5rem;
			font-size: 1rem;
		}

		@media ${deviceHeight.mobileM} {
			margin: 0.5rem 2rem;
			font-size: 1rem;
		}
	}
`

export const DetailButton = styled.button`
	padding: 1rem 2rem;
	color: #313131;
	background: white;
	border: 2px solid black;
	margin: 0rem 2rem;
	font-weight: 600;

	@media ${deviceWidth.mobileM} {
		margin: 0.5rem;
	}
`

export const DetailInfo = styled.div`
	margin-left: 10rem;

	@media ${deviceWidth.mobileM} {
		margin: 1rem 0rem;
	}

	@media ${deviceHeight.mobileM} {
		margin: 1rem 0rem;
	}

	div.buttons {
		@media ${deviceWidth.mobileM} {
			display: flex;
			width: 80vw;
		}
	}

	div.texts {
		margin: 2rem 0.5rem;

		@media ${deviceWidth.mobileM} {
			margin: 2rem 1rem;
			width: 80vw;
		}

		@media ${deviceHeight.mobileM} {
			margin: 2rem 2.5rem;
		}
	}
`
export const SplideWrapper = styled.div`
	margin: 0.5rem 0rem;

	h3 {
		@media ${deviceHeight.mobileM} {
			font-size: 1rem;
			margin: 0;
		}
	}
`

export const SplideSlideCard = styled.div`
	min-height: 14rem;
	border-radius: 2rem;
	overflow: hidden;
	position: relative;

	@media ${deviceWidth.mobileM} {
		min-height: 12rem;
	}

	@media ${deviceHeight.mobileM} {
		min-height: 6rem;
	}

	img {
		border-radius: 2rem;
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	p {
		position: absolute;
		z-index: 10;
		left: 50%;
		bottom: 0%;
		transform: translate(-50%, 0%);
		color: white;
		width: 100%;
		height: 40%;
		font-weight: 600;
		font-size: 1.5rem;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.5rem;

		@media ${deviceWidth.mobileM} {
			font-size: 0.8rem;
			height: 50%;
			margin: 0rem;
		}

		@media ${deviceHeight.mobileM} {
			font-size: 0.7rem;
			height: 50%;
			margin: 0rem;
		}
	}
`

export const Gradient = styled.div`
	position: absolute;
	z-index: 3;
	width: 100%;
	height: 100%;
	background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
`
