import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { MdClear } from "react-icons/md"
import { StyledForm } from "./StyledComponents"
import Axios from "axios"
import { serverLocation } from "../location"

export default function FormFilterItems() {
	const [input, setInput] = useState("")
	const [input2, setInput2] = useState("")
	const [input3, setInput3] = useState("all")
	const [input4, setInput4] = useState(false)
	const [input5, setInput5] = useState(false)
	const [input6, setInput6] = useState(false)

	const navigate = useNavigate()

	// Input Field handler
	function handleUserInput(e) {
		setInput(e.target.value)
	}
	function handleUserInput2(e) {
		setInput2(e.target.value)
	}
	function handleUserInput3(e) {
		setInput3(e.target.value)
	}
	function handleUserInput4(e) {
		setInput4(e.target.checked)
	}
	function handleUserInput5(e) {
		setInput5(e.target.checked)
	}
	function handleUserInput6(e) {
		setInput6(e.target.checked)
	}

	// Reset Input Field
	function resetInputField() {
		setInput("")
	}
	function resetInputField2() {
		setInput2("")
	}

	function submitHandler(e) {
		// a default oldalfrissítést letiltjuk
		e.preventDefault()
		filterItems()
	}

	let finalInput, reqURL

	let arrayItems = []

	// Szűrés
	async function filterItems() {
		// Validálás

		// Ha semmit nem írt be / nem jelölt be
		if (
			input === "" &&
			input2 === "" &&
			input3 === "all" &&
			input4 === false &&
			input5 === false &&
			input6 === false
		) {
			alert("Túl sok találat! - Írj be / jelölj be valamilyen értéket!")
		} else {
			// Ha mindkét filmcímrészletet kitöltötte
			if (input !== "" && input2 !== "") {
				alert("Ne írj angol ÉS magyar filmcím-részletet is, csak az egyiket töltsd ki!")
			} else {
				// A beírt név/cím részlet
				if (input !== "" && input2 === "") {
					finalInput = input
				} else if (input2 !== "" && input === "") {
					finalInput = input2
				} else {
				}

				// Ha túl rövid a cím/név részlet
				if (finalInput && finalInput.length < 3) {
					alert("A filmcím-részlet minimum 3 karakteres legyen!")
				} else {
					// Ha speciális karaktereket tartalmaz
					if (
						finalInput &&
						(finalInput.includes("/") ||
							finalInput.includes("%") ||
							finalInput.includes("*") ||
							finalInput.includes('"'))
					) {
						alert('A filmcím-részlet nem tartalmazhat speciális karaktereket\n(pl. /, %, *, " stb.)!')
					} else {
						reqURL = serverLocation + "/filmadatbazis"

						// ha írtunk be angol filmcím részletet
						if (input) {
							// a REST API-nk url címe
							reqURL = reqURL + "/title=*" + input + "*"
						} else {
							reqURL = reqURL + "/title=*-*"
						}

						// ha bármelyik checkbox be van pipázva
						if (input4 === true || input5 === true || input6 === true) {
							reqURL = reqURL + "&status="

							if (input4 === true) {
								reqURL = reqURL + "1,"
							}
							if (input5 === true) {
								reqURL = reqURL + "2,"
							}
							if (input6 === true) {
								reqURL = reqURL + "0,"
							}
							reqURL = reqURL.substring(0, reqURL.length - 1)
						} else {
							reqURL = reqURL + "&status=any"
						}

						// nyelv szűrés
						reqURL = reqURL + "&lang=" + input3

						// ha írtunk be magyar filmcím részletet
						if (input2) {
							// a REST API-nk url címe
							reqURL = reqURL + "&titlehun=*" + input2 + "*"
						} else {
							reqURL = reqURL + "&titlehun=*-*"
						}

						await Axios.get(reqURL).then((response) => {
							arrayItems = response.data
						})

						if (arrayItems.length > 0) {
							arrayItems.forEach((item, i) => {
								item.id = i + 1
							})

							navigate("/filteredresults", { state: { v1: arrayItems } })
						} else {
							alert("Nincs találat!")
						}
					}
				}
			}
		}
	}

	return (
		<StyledForm onSubmit={submitHandler}>
			<div>
				<input
					id="titleeng"
					value={input}
					onChange={handleUserInput}
					placeholder="Címrészlet ANGOL"
					type="text"
					spellCheck="false"
					autoFocus
				/>
				<MdClear id="clearinput" className="clear" onClick={resetInputField} />
			</div>
			<div>
				<input
					id="titlehun"
					value={input2}
					onChange={handleUserInput2}
					placeholder="Címrészlet MAGYAR"
					type="text"
					spellCheck="false"
				/>
				<MdClear id="clearinput2" className="clear" onClick={resetInputField2} />
			</div>
			<div>
				<select id="language" className="dropdown" value={input3} onChange={handleUserInput3}>
					<option value="all">MIND</option>
					<option value="en">Angol</option>
					<option value="hu">Magyar</option>
					<option value="de">Német</option>
					<option value="fr">Francia</option>
					<option value="it">Olasz</option>
					<option value="es">Spanyol</option>
				</select>
			</div>

			<div className="filtering-checkboxes" id="checkboxes">
				<div className="filtering-elements" id="check1">
					<label className="checkboxlabel">Láttam (1)</label>
					<input type="checkbox" className="checkbox" id="seen" value={input4} onChange={handleUserInput4} />
				</div>
				<div className="filtering-elements" id="check2">
					<label className="checkboxlabel">Watchlist (2)</label>
					<input type="checkbox" className="checkbox" id="wish" value={input5} onChange={handleUserInput5} />
				</div>
				<div className="filtering-elements" id="check3">
					<label className="checkboxlabel" id="other-label">
						Egyéb (0)
					</label>
					<input
						value={input6}
						onChange={handleUserInput6}
						type="checkbox"
						className="checkbox"
						id="notseenorwish"
					/>
				</div>
			</div>
			<button type="submit" value="Submit">
				Szűrés
			</button>
		</StyledForm>
	)
}
