import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { StyledForm } from "../components/StyledComponents"
import AppTitle from "../components/AppTitle"
import Axios from "axios"
import { useNavigate, useLocation } from "react-router-dom"
import { deviceWidth, deviceHeight } from "../device"
import { Button } from "../stories/Button"
import { serverLocation } from "../location"

export default function Login() {
	const [input, setInput] = useState("")
	const [input2, setInput2] = useState("")
	const [input3, setInput3] = useState("")
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		if (location.state) {
			setInput3(location.state.v1)
		} else {
			setInput3("")
		}
	}, [])

	// Input Field handler
	function handleUserInput(e) {
		setInput(e.target.value)
	}
	function handleUserInput2(e) {
		setInput2(e.target.value)
	}

	function submitHandler(e) {
		e.preventDefault()
		Axios.post(serverLocation + "/validateuser", { username: input, password: input2 }).then((response) => {
			console.log(response.data)
			let arrayResponse = response.data

			if (arrayResponse.user) {
				setInput3("")
				localStorage.setItem("user", JSON.stringify(arrayResponse))
				navigate("/filteritems")
			} else {
				setInput3(arrayResponse.error)
				setInput("")
				setInput2("")
			}
		})
	}

	return (
		<>
			<AppTitle />
			<LoginWrapper>
				<h1>Login</h1>
				<div className="alert alert-success" role="alert" id="loginAlert">
					<p id="errormessage">{input3}</p>
				</div>

				<StyledForm>
					<div className="credentials-div" id="username-div">
						<input
							type="text"
							value={input}
							onChange={handleUserInput}
							placeholder="Username"
							name="username"
							id="username"
							required
							autoFocus
						/>
					</div>
					<div className="credentials-div" id="password-div">
						<input
							type="password"
							value={input2}
							onChange={handleUserInput2}
							placeholder="Password"
							name="password"
							id="password"
							required
						/>
					</div>

					<input type="submit" value="Login" onClick={submitHandler} />
				</StyledForm>
			</LoginWrapper>
		</>
	)
}

const LoginWrapper = styled.div`
	width: 80%;
	max-width: 600px;
	background-color: #ffffff;
	box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	@media ${deviceHeight.mobileM} {
		width: 70%;
		height: 70%;
		top: 65%;
	}
	h1 {
		text-align: center;
		color: #5b6574;
		font-size: 24px;
		padding: 10px 0 10px 0;
		border-bottom: 1px solid #dee0e4;
		@media ${deviceHeight.mobileM} {
			padding: 0.5rem 0 0.5rem 0;
			font-size: 1rem;
		}
	}
	form {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 20px;
		@media ${deviceHeight.mobileM} {
			padding: 0.5rem;
		}
	}
	.label {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		background-color: #3274d6;
		color: #ffffff;
	}
	form input[type="password"],
	form input[type="text"] {
		width: 100%;
		height: 3.5rem;
		border: 1px solid #dee0e4;
		border-radius: 2vh;
		margin-bottom: 20px;
		padding: 0rem 1rem;
		@media ${deviceWidth.mobileM} {
			font-size: 1.2rem;
			margin-top: 0.5rem;
			height: 3rem;
			padding: 0rem 1rem;
		}
		@media ${deviceHeight.mobileM} {
			font-size: 0.9rem;
			height: 2.5rem;
			margin-bottom: 0.5rem;
		}
	}
	form input[type="submit"] {
		width: 100%;
		height: 3.5rem;
		padding: 0rem 1rem;
		margin-top: 20px;
		background-color: #3274d6;
		border: 0;
		border-radius: 2vh;
		cursor: pointer;
		font-weight: bold;
		color: #ffffff;
		transition: background-color 0.2s;
		@media ${deviceWidth.mobileM} {
			font-size: 1.2rem;
			margin-top: 0.5rem;
			height: 3rem;
			padding: 0rem 1rem;
		}
		@media ${deviceHeight.mobileM} {
			font-size: 0.9rem;
			margin-top: 0.5rem;
			height: 2.5rem;
			padding: 0.2rem;
		}
	}
	form input[type="submit"]:hover {
		background-color: #2868c7;
		transition: background-color 0.2s;
	}
	#loginAlert {
		display: block;
		padding-top: 15px;
		@media ${deviceHeight.mobileM} {
			padding-top: 0.5rem;
		}
	}
	.credentials-div {
		display: flex;
		width: 100%;
	}
	#errormessage {
		margin: 0;
		color: rgb(194, 36, 36);
		text-align: center;
		@media ${deviceHeight.mobileM} {
			font-size: 0.8rem;
		}
	}
`
