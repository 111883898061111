import React, { useState } from "react"
import { MdClear } from "react-icons/md"
import { StyledForm } from "./StyledComponents"
import Axios from "axios"
import { serverLocation } from "../location"

export default function FormFilterItems() {
	const [input, setInput] = useState("")
	const [input2, setInput2] = useState("")
	const [input3, setInput3] = useState("")
	const [input4, setInput4] = useState("")
	const [input5, setInput5] = useState("")
	const [input6, setInput6] = useState("")

	// Input Field handler
	function handleUserInput(e) {
		setInput(e.target.value)
	}
	function handleUserInput2(e) {
		setInput2(e.target.value)
	}
	function handleUserInput3(e) {
		setInput3(e.target.value)
	}
	function handleUserInput4(e) {
		setInput4(e.target.value)
	}
	function handleUserInput5(e) {
		setInput5(e.target.value)
	}
	function handleUserInput6(e) {
		setInput6(e.target.value)
	}

	// Reset Input Field
	function resetInputField() {
		setInput("")
	}
	function resetInputField2() {
		setInput2("")
	}
	function resetInputField3() {
		setInput3("")
	}
	function resetInputField4() {
		setInput4("")
	}
	function resetInputField5() {
		setInput5("")
	}
	function resetInputField6() {
		setInput6("")
	}

	function submitHandler(e) {
		// a default oldalfrissítést letiltjuk
		e.preventDefault()
		sendNewItem()
	}

	let reqURL

	let arrayItems = []

	// Szűrés
	async function sendNewItem() {
		// Validálás

		// Ha semmit nem írt be / nem jelölt be
		if (input === "" || input2 === "" || input3 === "" || input4 === "" || input5 === "" || input6 === "") {
			alert("Írj be minden értéket!")
		} else {
			let inputArray = []
			inputArray.push(input)
			inputArray.push(input2)
			inputArray.push(input3)
			inputArray.push(input4)
			inputArray.push(input5)
			inputArray.push(input6)

			let isAllCorrect = true

			// Ha valamelyik mező speciális karaktereket tartalmaz
			inputArray.forEach((item) => {
				if (item.includes("/") || item.includes("%") || item.includes("*") || item.includes('"')) {
					alert('A mezők nem tartalmazhatnak speciális karaktereket\n(pl. /, %, *, " stb.)!')
					isAllCorrect = false
				}
			})

			// Ha a státusz nem 0, 1, 2
			if (input3 != 0 && input3 != 1 && input3 != 2) {
				alert("A Státusz csak 0, 1 vagy 2 lehet!")
				isAllCorrect = false
			}

			// Ha az év nem 4-jegyű szám
			if (isNaN(input6)) {
				alert("Az Év csak szám lehet!")
				isAllCorrect = false
			}

			if (isAllCorrect) {
				reqURL = serverLocation + "/filmadatbazis/insert"

				reqURL = reqURL + "&titleeng=" + input
				reqURL = reqURL + "&titlehun=" + input2
				reqURL = reqURL + "&status=" + input3
				reqURL = reqURL + "&imdbid=" + input4
				reqURL = reqURL + "&lang=" + input5
				reqURL = reqURL + "&year=" + input6

				await Axios.post(reqURL).then((response) => {
					arrayItems = response.data
				})

				if (arrayItems.errno) {
					alert("\nHiba!\n\n" + arrayItems.sqlMessage)
				} else {
					resetInputField()
					resetInputField2()
					resetInputField3()
					resetInputField4()
					resetInputField5()
					resetInputField6()
					alert("Sikeresen feltöltve!")
				}
			}
		}
	}

	return (
		<StyledForm onSubmit={submitHandler}>
			<div>
				<input
					id="titleeng"
					value={input}
					onChange={handleUserInput}
					placeholder="Cím ANGOL"
					type="text"
					spellCheck="false"
					autoFocus
				/>
				<MdClear id="clearinput" className="clear" onClick={resetInputField} />
			</div>
			<div>
				<input
					id="titlehun"
					value={input2}
					onChange={handleUserInput2}
					placeholder="Cím MAGYAR"
					type="text"
					spellCheck="false"
				/>
				<MdClear id="clearinput2" className="clear" onClick={resetInputField2} />
			</div>
			<div>
				<input
					id="status"
					value={input3}
					onChange={handleUserInput3}
					placeholder="Státusz"
					type="text"
					spellCheck="false"
				/>
				<MdClear id="clearinput3" className="clear" onClick={resetInputField3} />
			</div>
			<div>
				<input
					id="imdbid"
					value={input4}
					onChange={handleUserInput4}
					placeholder="IMDB ID"
					type="text"
					spellCheck="false"
				/>
				<MdClear id="clearinput4" className="clear" onClick={resetInputField4} />
			</div>
			<div>
				<input
					id="language"
					value={input5}
					onChange={handleUserInput5}
					placeholder="Nyelv"
					type="text"
					spellCheck="false"
				/>
				<MdClear id="clearinput5" className="clear" onClick={resetInputField5} />
			</div>
			<div>
				<input
					id="year"
					value={input6}
					onChange={handleUserInput6}
					placeholder="Év"
					type="text"
					spellCheck="false"
				/>
				<MdClear id="clearinput6" className="clear" onClick={resetInputField6} />
			</div>

			<button type="submit" value="Submit">
				Elküldés
			</button>
		</StyledForm>
	)
}
