import React, { useEffect } from "react"
import FormFilterItems from "../components/FormFilterItems"
import Nav from "../components/Nav"
import { useNavigate } from "react-router-dom"
// import Axios from "axios"
// import { winLocation } from "../location"

export default function FilterItems() {
	const navigate = useNavigate()
	// const [isToken, setIsToken] = useState("")

	useEffect(() => {
		if (!localStorage.getItem("user")) {
			navigate("/")
		} else {
			// Node.js
			// let URL = winLocation + "/verifytoken"
			// PHP
			// let URL = winLocation
			// Axios.post(URL, { token: localStorage.getItem("user").token }).then((response) => {
			// 	setIsToken(response.data)
			// })
			// console.log(isToken)
		}
	}, [])

	return (
		<div>
			<Nav />
			<FormFilterItems />
		</div>
	)
}
