import React from "react"
import styled from "styled-components"
import { FcFilmReel } from "react-icons/fc"
import { deviceWidth, deviceHeight } from "../device"

export default function AppTitle() {
	return (
		<TitleWrapper>
			<FcFilmReel id="loginicon" />
			<h1>Film Database - Szabi</h1>
		</TitleWrapper>
	)
}

const TitleWrapper = styled.div`
	text-align: center;
	margin-top: 2.5rem;

	@media ${deviceWidth.mobileM} {
		font-size: 0.7rem;
	}

	@media ${deviceHeight.mobileM} {
		margin-top: 1rem;
		font-size: 0.5rem;
	}

	#loginicon {
		font-size: 3rem;
	}
`
