import React, { useState } from "react"
import { StyledForm } from "./StyledComponents"
import Axios from "axios"
import { useNavigate } from "react-router-dom"
import { serverLocation } from "../location"

export default function FormUpdateStatus({ filmid, rows }) {
	const [input, setInput] = useState(1)
	const [isUpdated, setIsUpdated] = useState(false)

	// Input Field handler
	function handleUserInput(e) {
		setInput(+e.target.value)
	}

	function submitHandler(e) {
		// a default oldalfrissítést letiltjuk
		e.preventDefault()
		updateStatus(filmid)
	}

	const navigate = useNavigate()

	let reqURL

	// Szűrés
	async function updateStatus(idToUpdate) {
		// Validálás

		// Ha semmit nem írt be / nem jelölt be
		if (input === "") {
			alert("Válaszd ki valamelyik értéket (1: Láttam, 2: Watchlist, 0: Egyéb)!")
		} else {
			reqURL = serverLocation + "/filmadatbazis/update/id=" + idToUpdate + "/status=" + input

			await Axios.patch(reqURL).then((response) => {
				if (response.data.errno) {
					alert("\nHiba!\n\n" + response.data.sqlMessage)
				} else {
					setIsUpdated(!isUpdated)
					// Az adott film sorának frissítése
					rows.forEach((object) => {
						if (object.filmid === filmid) {
							object.szabistatusz = input
						}
					})
					alert("Státusz frissítve!")
					navigate("/filteredresults")
				}
			})
		}
	}

	return (
		<div>
			{!isUpdated && (
				<StyledForm
					onSubmit={submitHandler}
					style={{
						width: "30%",
						margin: "auto",
						display: "flex",
						gap: "1rem",
						alignItems: "center",
						justifyContent: "space-evenly",
					}}
				>
					<div style={{ alignItems: "center" }}>
						<select
							id="status"
							className="dropdown"
							style={{ height: "3rem", width: "5rem", fontSize: "100%" }}
							value={input}
							onChange={handleUserInput}
						>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="0">0</option>
						</select>
					</div>
					<div style={{ alignItems: "center" }}>
						<button type="submit" value="Submit" style={{ margin: "0rem" }}>
							Frissítés
						</button>
					</div>
				</StyledForm>
			)}
		</div>
	)
}
