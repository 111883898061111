import React, { useState } from "react"
import { deviceWidth, deviceHeight } from "../device"
import { DataGrid } from "@mui/x-data-grid"
import FormUpdateStatus from "./FormUpdateStatus"

export default function GridMUI({ rows }) {
	const [isRowSelected, setIsRowSelected] = useState(false)
	const [filmId, setFilmId] = useState("")

	// A Data Grid oszlopaiban mely mezők jelenjenek meg az object-ekből
	const columns = [
		{ field: "filmid", headerName: "ID", flex: 0.5, minWidth: 30, align: "center" },
		{ field: "cim", headerName: "Cím", flex: 2.3, minWidth: 80 },
		{ field: "szabistatusz", headerName: "St.", flex: 0.3, minWidth: 30, align: "center" },
		{ field: "kibocsatasev", headerName: "Év", flex: 0.4, minWidth: 50, align: "center" },
		{ field: "eredetinyelv", headerName: "Ny.", flex: 0.3, minWidth: 40, align: "center" },
		{ field: "magyar_cim", headerName: "Magyar cím", flex: 2.3, minWidth: 80 },

		{
			field: "link",
			headerName: "Link",
			flex: 0.3,
			minWidth: 40,
			align: "center",
			renderCell: (params) => {
				return (
					<a href={`${params.value}`} rel="noreferrer" target="_blank">
						Link
					</a>
				)
			},
		},
	]

	function handleCellClick(param) {
		if (param.field !== "link") {
			setFilmId(param.row.filmid)
			setIsRowSelected(!isRowSelected)
		}
	}

	return (
		<>
			<div>{isRowSelected && <FormUpdateStatus filmid={filmId} rows={rows} />}</div>
			<div id="grid-container" style={gridContainerStyles}>
				<div id="data-grid" style={{ flexGrow: 1 }}>
					{rows && (
						<DataGrid
							rows={rows}
							columns={columns}
							headerHeight={50}
							rowHeight={40}
							rowsPerPageOptions={[25, 50, 100, 200]}
							showBorders={true}
							selection={{ mode: "single" }}
							onCellClick={handleCellClick}
							initialState={{
								columns: {
									columnVisibilityModel: {
										filmid: false,
									},
								},
							}}
							sx={{
								boxShadow: 2,
								border: 2,
								borderColor: "primary.light",
								overflowWrap: "break-word",
								[`@media ${deviceWidth.mobileM}`]: {
									fontSize: "0.45rem",

									"& .MuiDataGrid-cell": {
										paddingLeft: "0.2rem",
									},
								},
								[`@media ${deviceHeight.mobileM}`]: {
									fontSize: "0.6rem",
								},
								"& .MuiDataGrid-columnHeaders": {
									backgroundColor: "#5fc2e999",
								},
								"& .MuiDataGrid-columnHeaderTitle": {
									textOverflow: "clip",
									fontWeight: "bold",
									whiteSpace: "break-spaces",
									lineHeight: 1.5,
								},
							}}
						/>
					)}
				</div>
			</div>
		</>
	)
}

const gridContainerStyles = {
	display: "flex",
	height: "70vh",
	width: "95%",
	marginTop: "0.5rem",
	marginLeft: "auto",
	marginRight: "auto",
	flexDirection: "column",
}
