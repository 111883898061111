const size = {
	mobileS: "375px",
	mobileM: "425px",
	mobileL: "768px",
	tablet: "1024px",
	laptop: "1440px",
	laptopL: "2560px",
}

export const deviceWidth = {
	mobileS: `(max-width: ${size.mobileS})`,
	mobileM: `(max-width: ${size.mobileM})`,
	mobileL: `(max-width: ${size.mobileL})`,
	tablet: `(max-width: ${size.tablet})`,
	laptop: `(max-width: ${size.laptop})`,
	laptopL: `(max-width: ${size.laptopL})`,
}

export const deviceHeight = {
	mobileS: `(max-height: ${size.mobileS})`,
	mobileM: `(max-height: ${size.mobileM})`,
	mobileL: `(max-height: ${size.mobileL})`,
	tablet: `(max-height: ${size.tablet})`,
	laptop: `(max-height: ${size.laptop})`,
	laptopL: `(max-height: ${size.laptopL})`,
}
